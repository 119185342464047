import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Kural from './pages/Kural';
import Chapter from './pages/Chapter';
import KuralDay from './pages/KuralDay';
import Layout from './pages/Layout';
import Home from './pages/Home.js';

// import About from './pages/About';
// import User from './pages/User';
// import Privacy from './pages/Privacy';
// import useToken from "./comp/useToken";

function App() {
  // const {token, setToken} = useToken({});

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"  element={<Layout />}>
          <Route path=":id" element={<Kural />} />
          <Route path="ch" element={<Chapter />}>
            <Route path=":chapter" element={<Chapter />}/>
          </Route>
          <Route index element={<Home />} >
          </Route>
          <Route path="kofd" element={<KuralDay />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;