/*
 * Intro card. Just returns the intro as static content.
 */
export default function IntroCard(props) {
    return (
        <small className="card-text">
            <p>The Thirukkural or Thirukural or Kural is a great classical Tamil language short couplets.</p>
            <p>It is composed of 133 chapters, with each chapter consisting of 10 couplets.</p>
            <p>It was authored by Thiruvalluvar (or Saint Valluvar) probably in post-Sangam era (c. 500 CE or earlier).</p>
            <p>The book is divided into three parts on a high level, each describing a theme:</p>
            <ol>
                <p>
                    <li>Aṟam (அறத்துப்பால்): Book of Virtue<br />
                        Deals with moral values of an individual<br />
                        Chapters 1 - 38<br />
                    </li>
                </p>
                <p>
                    <li>Poruḷ (பொருட்பால்): Book of Polity<br />
                        Deals with wealth and socio-economic values<br />
                        Chapters 39 - 108<br />
                    </li>
                </p>
                <p>
                    <li>Kamam (காமத்துப்பால்): Book of Love<br />
                        Deals with love and psychological values<br />
                        Chapters 109 - 133<br />
                    </li>
                </p>
            </ol>
        </small>
    );
}