import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import Accordion from '../comp/Accordion';
import ValluvarCard from '../comp/ValluvarCard';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';

export default function Chapter(props) {
    const chCount = 133;
    let { chapter = 1 } = useParams();
    const [kurals, setKurals] = useState([]);
    const [pageOffset, setPageOffset] = useState(0);
    const [title, setTitle] = useState('Learn Thirukkural');

    useEffect(() => {
      async function fetchKurals(chapter) {
        const response = await fetch(
            `/.netlify/functions/kurals?${new URLSearchParams({
                    page:(chapter-1)
                })}`
            );

        const resJson = await response.json();
        if (!response.ok) {
            console.log(response.reason);
            return;
        }
        console.log("Response from lambda...");
        console.log(resJson);
        setKurals(resJson.kurals);
        const newTitle = chapter > 0 ? 
            'Learn Thirukkural - Chapter ' + chapter : 
            'Learn Thirukkural';
        setTitle(newTitle);
        setPageOffset(chapter - 1);
      }
      fetchKurals(chapter);
    }, [chapter]);

    const navigate = useNavigate();

    const handlePageChange = (event) => {
        console.log(event);
        // TODO Only change displayed selected page
        // when its content is loaded in useEffect.
        setPageOffset(event.selected);
        navigate("/ch/" + (event.selected + 1))
    };

    return (
        <div className="m-2">
            <HelmetProvider>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            </HelmetProvider>
            <ValluvarCard items={kurals[0]} />
            <div className='mx-1'>
                <nav aria-label="breadcrumb" className='mx-0' style={{ bsBreadcrumbDivider: '>'}}>
                    <ol className="breadcrumb px-0">
                        <li className="breadcrumb-item px-0 m-0 btn-sm"><a href="/#">Home </a></li>
                        <li className="breadcrumb-item btn-sm active" aria-current="page">
                            Chapter {chapter} 
                        </li>
                    </ol>
                </nav>
            </div>
            <Accordion items={kurals} />
            <small>
                <ReactPaginate
                    pageCount={chCount}
                    breakLabel="..."
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName='page-item'
                    pageLinkClassName='page-link'
                    nextClassName='page-item'
                    previousClassName='page-item'
                    nextLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    containerClassName='pagination justify-content-center'
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    activeClassName="active"
                    forcePage={pageOffset}
                    onPageChange={handlePageChange}
                />
            </small>
        </div>
    );
}