import { useState } from 'react'
import { Collapse } from 'react-collapse'

/*
 * Returns the kural's other poet's explanation panel.
 */
export default function OtherPoetsExpPanel(props) {
    const item = props.item
    const [isOpened, setIsOpened] = useState(true);

    return (
        <div key={item.id}>
            <label className='mx-0 p-0 btn-sm '
                onClick={() => { setIsOpened(!isOpened) }}>
                Poets Explanation {isOpened ? "-" : "+"}
            </label>
            <Collapse isOpened={isOpened}>
                <div className='row m-0 p-0'>
                    {item.mv && <p>மு.வரதராசனார்: {item.mv}</p>}
                </div>
                <div className='row m-0 p-0'>
                    {item.sp && <p>சாலமன் பாப்பையா: {item.sp}</p>}
                </div>
                {props.mk && props.mk === true && (
                    <div className='row m-0 p-0'>
                    {item.mk && <p>மு.கருணாநிதி: {item.mk}</p>}
                </div>
                )}
            </Collapse>
        </div>
    )
}