import { Collapse } from "react-collapse";
import { Link } from 'react-router-dom';
import { useState } from "react";

export default function PalsCard(props) {
    const [virChOpen, setVirChOpen] = useState(true);
    const [polChOpen, setPolChOpen] = useState(false);
    const [lovChOpen, setLovChOpen] = useState(false);

    function getChLinkNo(chapter) {
        return <span key={chapter.ch}>
            <Link className="nav-link m-0 p-0" to={'/ch/' + chapter.ch}>{chapter.ch + ' '} </Link>
        </span>
    }
    function getChLinkEName(chapter) {
        const name = chapter.ename.length > 25 ? chapter.ename.substr(0, 24) + "..." : chapter.ename;
        return <span key={chapter.ch}>
            <Link className="nav-link m-0 p-0" to={'/ch/' + chapter.ch}>{name} </Link>
        </span>
    }
    function getChLinkTName(chapter) {
        const name = chapter.tname.length > 16 ? chapter.tname.substr(0, 15) + "..." : chapter.tname;
        return <span key={chapter.ch}>
            <Link className="nav-link m-0 p-0" to={'/ch/' + chapter.ch}>{name} </Link>
        </span>
    }
    return (
        <small>
            <ul className="list-group list-group-flush">
                <li className="list-group-item m-0 p-0">
                    <label className='mx-0 p-0 btn-sm link-primary'
                        onClick={() => { setVirChOpen(!virChOpen) }}>
                        <h6>அறத்துப்பால் &nbsp;&nbsp; [Book of Virtue] {virChOpen ? "-" : "+"}</h6>
                    </label>
                    <Collapse isOpened={virChOpen}>
                        <div className='m-0 p-0'>
                            <table><tbody>
                                <tr>
                                    <td>{virChapters.map(getChLinkNo)}</td>
                                    <td>{virChapters.map(getChLinkTName)}</td>
                                    <td>{virChapters.map(getChLinkEName)}</td>
                                </tr>
                            </tbody></table>
                        </div>
                    </Collapse>
                </li>
                <li className="list-group-item m-0 p-0">
                    <label className='mx-0 p-0 btn-sm link-primary'
                        onClick={() => { setPolChOpen(!polChOpen) }}>
                        <h6>பொருட்பால் &nbsp;&nbsp;&nbsp; [Book of Polity] {polChOpen ? "-" : "+"}</h6>
                    </label>
                    <Collapse isOpened={polChOpen}>
                        <div className='m-0 p-0'>
                            <table><tbody>
                                <tr>
                                    <td>{polChapters.map(getChLinkNo)}</td>
                                    <td>{polChapters.map(getChLinkTName)}</td>
                                    <td>{polChapters.map(getChLinkEName)}</td>
                                </tr>
                            </tbody></table>
                        </div>
                    </Collapse>
                </li>
                <li className="list-group-item m-0 p-0">
                    <label className='mx-0 p-0 btn-sm link-primary'
                        onClick={() => { setLovChOpen(!lovChOpen) }}>
                        <h6>காமத்துப்பால் &nbsp; [Book of Love ] {lovChOpen ? "-" : "+"}</h6>
                    </label>
                    <Collapse isOpened={lovChOpen}>
                        <div className='m-0 p-0'>
                            <table><tbody>
                                <tr>
                                    <td>{lovChapters.map(getChLinkNo)}</td>
                                    <td>{lovChapters.map(getChLinkTName)}</td>
                                    <td>{lovChapters.map(getChLinkEName)}</td>
                                </tr>
                            </tbody></table>
                        </div>
                    </Collapse>
                </li>
            </ul>
        </small>
    );
}

const virChapters = [
    { ch: 1, ename: "The Praise of God", tname: "கடவுள் வாழ்த்து" },
    { ch: 2, ename: "The Blessing of Rain", tname: "வான்சிறப்பு" },
    { ch: 3, ename: "The Greatness of Ascetics", tname: "நீத்தார் பெருமை" },
    { ch: 4, ename: "Assertion of the Strength of Virtue", tname: "அறன் வலியுறுத்தல்" },
    { ch: 5, ename: "Domestic Life", tname: "இல்வாழ்க்கை" },
    { ch: 6, ename: "The Worth of a Wife", tname: "வாழ்க்கைத் துணைநலம்" },
    { ch: 7, ename: "The Wealth of Children", tname: "மக்கட்பேறு" },
    { ch: 8, ename: "The Possession of Love", tname: "அன்புடைமை" },
    { ch: 9, ename: "Hospitality", tname: "விருந்தோம்பல்" },
    { ch: 10, ename: "The Utterance of Pleasant Words", tname: "இனியவை கூறல்" },
    { ch: 11, ename: "Gratitude", tname: "செய்ந்நன்றியறிதல்" },
    { ch: 12, ename: "Impartiality", tname: "நடுவு நிலைமை" },
    { ch: 13, ename: "The Possession of Self-restraint", tname: "அடக்கம் உடைமை" },
    { ch: 14, ename: "The Possession of Decorum", tname: "ஒழுக்கம் உடைமை" },
    { ch: 15, ename: "Not coveting another's Wife", tname: "பிறனில் விழையாமை" },
    { ch: 16, ename: "The Possession of Patience, Forbearance", tname: "பொறையுடைமை" },
    { ch: 17, ename: "Not Envying", tname: "அழுக்காறாமை" },
    { ch: 18, ename: "Not Coveting", tname: "வெஃகாமை" },
    { ch: 19, ename: "Not Backbiting", tname: "புறங்கூறாமை" },
    { ch: 20, ename: "Against Vain Speaking", tname: "பயனில சொல்லாமை" },
    { ch: 21, ename: "Dread of Evil Deeds", tname: "தீவினையச்சம்" },
    { ch: 22, ename: "Duty to Society", tname: "ஒப்புரவறிதல்" },
    { ch: 23, ename: "Giving", tname: "ஈகை" },
    { ch: 24, ename: "Renown", tname: "புகழ்" },
    { ch: 25, ename: "Compassion", tname: "அருளுடைமை" },
    { ch: 26, ename: "Abstinence from Flesh", tname: "புலால் மறுத்தல்" },
    { ch: 27, ename: "Penance", tname: "தவம்" },
    { ch: 28, ename: "Imposture", tname: "கூடா ஒழுக்கம்" },
    { ch: 29, ename: "The Absence of Fraud", tname: "கள்ளாமை" },
    { ch: 30, ename: "Veracity", tname: "வாய்மை" },
    { ch: 31, ename: "Restraining Anger", tname: "வெகுளாமை" },
    { ch: 32, ename: "Not doing Evil", tname: "இன்னா செய்யாமை" },
    { ch: 33, ename: "Not killing", tname: "கொல்லாமை" },
    { ch: 34, ename: "Instability", tname: "நிலையாமை" },
    { ch: 35, ename: "Renunciation", tname: "துறவு" },
    { ch: 36, ename: "Truth-Conciousness", tname: "மெய்யுணர்தல்" },
    { ch: 37, ename: "Curbing of Desire", tname: "அவா அறுத்தல்" },
    { ch: 38, ename: "Fate", tname: "ஊழ்" }
];

const polChapters = [
    { ch: 39, ename: "The Greatness of a King", tname: "இறைமாட்சி" },
    { ch: 40, ename: "Learning", tname: "கல்வி" },
    { ch: 41, ename: "Ignorance", tname: "கல்லாமை" },
    { ch: 42, ename: "Hearing", tname: "கேள்வி" },
    { ch: 43, ename: "The Possession of Knowledge", tname: "அறிவுடைமை" },
    { ch: 44, ename: "The Correction of Faults", tname: "குற்றங்கடிதல்" },
    { ch: 45, ename: "Seeking the Aid of Great Men", tname: "பெரியாரைத் துணைக்கோடல்" },
    { ch: 46, ename: "Avoiding mean Associations", tname: "சிற்றினம் சேராமை" },
    { ch: 47, ename: "Acting after due Consideration", tname: "தெரிந்து செயல்வகை" },
    { ch: 48, ename: "The Knowledge of Power", tname: "வலியறிதல்" },
    { ch: 49, ename: "Knowing the fitting Time", tname: "காலமறிதல்" },
    { ch: 50, ename: "Knowing the Place", tname: "இடனறிதல்" },
    { ch: 51, ename: "Selection and Confidence", tname: "தெரிந்து தெளிதல்" },
    { ch: 52, ename: "Selection and Employment", tname: "தெரிந்து வினையாடல்" },
    { ch: 53, ename: "Cherishing Kinsmen", tname: "சுற்றந் தழால்" },
    { ch: 54, ename: "Unforgetfulness", tname: "பொச்சாவாமை" },
    { ch: 55, ename: "The Right Sceptre", tname: "செங்கோன்மை" },
    { ch: 56, ename: "The Cruel Sceptre", tname: "கொடுங்கோன்மை" },
    { ch: 57, ename: "Absence of Terrorism", tname: "வெருவந்த செய்யாமை" },
    { ch: 58, ename: "Benignity", tname: "கண்ணோட்டம்" },
    { ch: 59, ename: "Detectives", tname: "ஒற்றாடல்" },
    { ch: 60, ename: "Energy", tname: "ஊக்கம் உடைமை" },
    { ch: 61, ename: "Unsluggishness", tname: "மடி இன்மை" },
    { ch: 62, ename: "Manly Effort", tname: "ஆள்வினை உடைமை" },
    { ch: 63, ename: "Hopefulness in Trouble", tname: "இடுக்கண் அழியாமை" },
    { ch: 64, ename: "The Office of Minister of state", tname: "அமைச்சு" },
    { ch: 65, ename: "Power of Speech", tname: "சொல்வன்மை" },
    { ch: 66, ename: "Purity in Action", tname: "வினைத் தூய்மை" },
    { ch: 67, ename: "Power in Action", tname: "வினைத்திட்பம்" },
    { ch: 68, ename: "Modes of Action", tname: "வினை செயல்வகை" },
    { ch: 69, ename: "The Envoy", tname: "தூது" },
    { ch: 70, ename: "Conduct in the Presence of the King", tname: "மன்னரைச் சேர்ந்து ஒழுகல்" },
    { ch: 71, ename: "The Knowledge of Indications", tname: "குறிப்பறிதல்" },
    { ch: 72, ename: "The Knowledge of the Council Chamber", tname: "அவை அறிதல்" },
    { ch: 73, ename: "Not to dread the Council", tname: "அவை அஞ்சாமை" },
    { ch: 74, ename: "The Land", tname: "நாடு" },
    { ch: 75, ename: "The Fortification", tname: "அரண்" },
    { ch: 76, ename: "Way of Accumulating Wealth", tname: "பொருள் செயல்வகை" },
    { ch: 77, ename: "The Excellence of an Army", tname: "படை மாட்சி" },
    { ch: 78, ename: "Military Spirit", tname: "படைச் செருக்கு" },
    { ch: 79, ename: "Friendship", tname: "நட்பு" },
    { ch: 80, ename: "Investigation in forming Friendships", tname: "நட்பாராய்தல்" },
    { ch: 81, ename: "Familiarity", tname: "பழைமை" },
    { ch: 82, ename: "Evil Friendship", tname: "தீ நட்பு" },
    { ch: 83, ename: "Unreal Friendship", tname: "கூடா நட்பு" },
    { ch: 84, ename: "Folly", tname: "பேதைமை" },
    { ch: 85, ename: "Ignorance", tname: "புல்லறிவாண்மை" },
    { ch: 86, ename: "Hostility", tname: "இகல்" },
    { ch: 87, ename: "The Might of Hatred", tname: "பகை மாட்சி" },
    { ch: 88, ename: "Knowing the Quality of Hate", tname: "பகைத்திறம் தெரிதல்" },
    { ch: 89, ename: "Enmity within", tname: "உட்பகை" },
    { ch: 90, ename: "Not Offending the Great", tname: "பெரியாரைப் பிழையாமை" },
    { ch: 91, ename: "Being led by Women", tname: "பெண்வழிச் சேறல்" },
    { ch: 92, ename: "Wanton Women", tname: "வரைவின் மகளிர்" },
    { ch: 93, ename: "Not Drinking Palm-Wine", tname: "கள்ளுண்ணாமை" },
    { ch: 94, ename: "Gambling", tname: "சூது" },
    { ch: 95, ename: "Medicine", tname: "மருந்து" },
    { ch: 96, ename: "Nobility", tname: "குடிமை" },
    { ch: 97, ename: "Honour", tname: "மானம்" },
    { ch: 98, ename: "Greatness", tname: "பெருமை" },
    { ch: 99, ename: "Perfectness", tname: "சான்றாண்மை" },
    { ch: 100, ename: "Courtesy", tname: "பண்புடைமை" },
    { ch: 101, ename: "Wealth without Benefaction", tname: "நன்றியில் செல்வம்" },
    { ch: 102, ename: "Shame", tname: "நாணுடைமை" },
    { ch: 103, ename: "The Way of Maintaining the Family", tname: "குடிசெயல் வகை" },
    { ch: 104, ename: "Farming", tname: "உழவு" },
    { ch: 105, ename: "Poverty", tname: "நல்குரவு" },
    { ch: 106, ename: "Mendicancy", tname: "இரவு" },
    { ch: 107, ename: "The Dread of Mendicancy", tname: "இரவச்சம்" },
    { ch: 108, ename: "Baseness", tname: "கயமை" },
];

const lovChapters = [
    { ch: 109, ename: "The Pre-marital love", tname: "தகை அணங்குறுத்தல்" },
    { ch: 110, ename: "Recognition of the Signs ", tname: "குறிப்பறிதல்" },
    { ch: 111, ename: "Rejoicing in the Embrace", tname: "புணர்ச்சி மகிழ்தல்" },
    { ch: 112, ename: "The Praise of her Beauty", tname: "நலம் புனைந்து உரைத்தல்" },
    { ch: 113, ename: "Declaration of Love's special Excellence", tname: "காதற் சிறப்புரைத்தல்" },
    { ch: 114, ename: "The Abandonment of Reserve", tname: "நாணுத் துறவுரைத்தல்" },
    { ch: 115, ename: "The Announcement of the Rumour", tname: "அலர் அறிவுறுத்தல்" },
    { ch: 116, ename: "Separation unendurable", tname: "பிரிவு ஆற்றாமை" },
    { ch: 117, ename: "Complainings", tname: "படர்மெலிந் திரங்கல்" },
    { ch: 118, ename: "Eyes consumed with Grief", tname: "கண் விதுப்பழிதல்" },
    { ch: 119, ename: "The Pallid Hue", tname: "பசப்புறு பருவரல்" },
    { ch: 120, ename: "The Solitary Anguish", tname: "தனிப்படர் மிகுதி" },
    { ch: 121, ename: "Sad Memories", tname: "நினைந்தவர் புலம்பல்" },
    { ch: 122, ename: "The Visions of the Night", tname: "கனவுநிலை உரைத்தல்" },
    { ch: 123, ename: "Lamentations at Eventide", tname: "பொழுதுகண்டு இரங்கல்" },
    { ch: 124, ename: "Wasting Away", tname: "உறுப்புநலன் அழிதல்" },
    { ch: 125, ename: "Soliloquy", tname: "நெஞ்சொடு கிளத்தல்" },
    { ch: 126, ename: "Reserve Overcome", tname: "நிறையழிதல்" },
    { ch: 127, ename: "Mutual Desire", tname: "அவர்வயின் விதும்பல்" },
    { ch: 128, ename: "The Reading of the Signs", tname: "குறிப்பறிவுறுத்தல்" },
    { ch: 129, ename: "Desire for Reunion", tname: "புணர்ச்சி விதும்பல்" },
    { ch: 130, ename: "Expostulation with Oneself", tname: "நெஞ்சொடு புலத்தல்" },
    { ch: 131, ename: "Pouting", tname: "புலவி" },
    { ch: 132, ename: "Feigned Anger", tname: "புலவி நுணுக்கம்" },
    { ch: 133, ename: "The Pleasures of Temporary Variance", tname: "ஊடலுவகை" },
];