import { useState, useEffect } from 'react';
import TranslationPanel from '../comp/TranslationPanel';
import OtherPoetsExpPanel from '../comp/OtherPoetsExpPanel';
import KuralClassInfo from '../comp/KuralClassInfo';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactPaginate from 'react-paginate';
import ReactAudioPlayer from 'react-audio-player';
import PicCard from '../comp/PicCard';

/*
 * Renders the kural's page.
 */
export default function Kural(props) {
    const { id } = useParams();
    const [kural, setKural] = useState({});
    const [title, setTitle] = useState('');
    const [chapter, setChapter] = useState(1);
    const [chapterLink, setChapterLink] = useState('');
    const [pageOffset, setPageOffset] = useState(0);
    const [audioLink, setAudioLink] = useState('');
    const [kuralTL, setKuralTL] = useState('');

    useEffect(() => {
        async function fetchKural(kuralId) {
            const response = await fetch(
                `/.netlify/functions/kurals?${new URLSearchParams({
                    kural: kuralId
                })}`
            );

            const resJson = await response.json();
            if (!response.ok) {
                console.log(response.reason);
                return;
            }
            console.log("Response from lambda...");
            console.log(resJson);
            setKural(resJson.kurals[0]);
            const newTitle = kuralId ? 'Thirukkural - No. ' + kuralId : 'Thirukkural';
            setTitle(newTitle)
            setChapter(Math.ceil(kuralId / 10));
            setChapterLink('/ch/' + Math.ceil(kuralId / 10));
            setPageOffset(kuralId-1);
            setKuralTL(resJson.kurals[0].tl1);

            const aLink = 'https://thirukkural.s3.us-west-2.amazonaws.com/audio/ch' + 
                Math.ceil(id / 10) + '/tk' + id + '.mp3';
            setAudioLink(aLink);
        }
        fetchKural(id);
    }, [id]);

    const navigate = useNavigate();

    const handlePageChange = (event) => {
       
        console.log(event);
        // TODO Only change displayed selected page
        // when its content is loaded in useEffect.
        setPageOffset(event.selected);
        navigate("/" + (event.selected + 1));
    };

    return (
        <div className="m-2">
            <HelmetProvider>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={kuralTL}/>
                </Helmet>
            </HelmetProvider>
            {/* <div className="card-header border-0 m-0 p-0 m-0 p-0 col-md-6 col-lg-6 col-xl-5 col-xxl-4" style={{ height: 200 }}>
                <img className="w-100 h-100" src="/Thiru.png" alt="" />
            </div> */}
            <PicCard />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/#">Home</a></li>
                    <li className="breadcrumb-item" aria-current="page">
                        <a href={chapterLink}>Chapter {chapter} </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Kural {kural.id} </li>
                </ol>
            </nav>
            <div className="col-12">
                <h3>
                    {kural.l1}<br />
                    {kural.l2}
                </h3>
            </div>
            <div className="col-12">
                <label className='mx-0 p-0 btn-sm'><p>{kural.ex}</p></label>
            </div>
            <div className="col-12">
                <KuralClassInfo item={kural} />
            </div>
            <div className="col-12">
                <OtherPoetsExpPanel item={kural} mk={true} />
            </div>
            <div className="col-12">
                <TranslationPanel item={kural} open={true} />
            </div>
            <div className='col'>
                <ReactAudioPlayer 
                    src={audioLink} 
                    controls 
                    controlsList='nodownload'/>
            </div>

            <small>
                <ReactPaginate
                    pageCount={1330}
                    breakLabel="..."
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName='page-item'
                    pageLinkClassName='page-link'
                    nextClassName='page-item'
                    previousClassName='page-item'
                    nextLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    containerClassName='pagination justify-content-center'
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={-1}
                    activeClassName="active"
                    forcePage={pageOffset}
                    onPageChange={handlePageChange}
                />
            </small>
        </div>
    );
}