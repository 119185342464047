import PicCard from "./PicCard";

export default function ValluvarCard(props) {
  const item = props.items;
  return (
    <div className="card flex-row flex-wrap border-0 m-0 p-0">
      <PicCard />
      <div className="card-block">
        <h2 className="card-title m-1">
          {item && item.paul && <span>{item.paul} ({item.paultl})</span>}
        </h2>
        <h4 className="card-text m-1">
          {item && item.adi && <span> {item.adi} ({item.aditl})</span>}
        </h4>
        <h5 className="card-text m-1">
          {item && item.iyal && <span> {item.iyal} ({item.iyaltl})</span>}
        </h5>
      </div>
    </div>
  );
}