import {
    Outlet,
    Link,
    useNavigate
} from 'react-router-dom';
import Footer from '../comp/Footer';
import KuralSearch from '../comp/KuralSearch';

/*
 * Returns the branch element.
 */
function Brand(props) {
    return (
        <Link className="navbar-brand m-0 p-0" to="/">
            {/* <img src="/llp-logo.png" width="30" className="p-0 m-0" alt="llp" /> */}
            Home
        </Link>
    );
}
/*
 * Returns the nav bar based on the screen.
 */
function NavBar(props) {
    const navigate = useNavigate();

    /*
     * Used to random kural
     */
    const randomKural = (e) => {
        e.preventDefault();
        const kuralId = Math.floor(Math.random() * (1330 - 1) + 1)
        navigate("/" + kuralId)
    }
    /*
     * Returns the nav bar
     */
    return (
        <div className="align-items-end">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-0 mb-lg-0">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="navbar-toggler-icon"></span>
                        </a>
                        <ul className="dropdown-menu bg-dark dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li className="nav-item">
                                <Link className="dropdown-item nav-link bg-dark text-white-50"
                                    to="/ch/1">
                                    Chapters
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item nav-link bg-dark text-white-50"
                                    to="/kofd">
                                    Kural of the day
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item nav-link bg-dark text-white-50"
                                    to="/"
                                    onClick={randomKural}>
                                    Random Kural
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="dropdown-item nav-link bg-dark text-white-50"
                                    to="/about">
                                    About
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}

/*
 * Renders the layout comp.
 */
export default function Layout(props) {
    return (
        <>
            <nav className="navbar navbar-expand navbar-dark bg-dark sticky-top p-0">
                <div className="container-fluid">
                    <Brand />
                    <KuralSearch />
                    <NavBar token={props.token} />
                </div>
            </nav>
            <Outlet />
            <Footer />
        </>
    );
}