import { useNavigate } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
/*
 * Returns the kural's search panel.
 */
export default function KuralSearch(props) {
    const navigate = useNavigate();

    let chapterlNums = [...new Array(133)].map((_, i) => ({
        id: i, // 0 - 132
        name: 'Chapter ' + (i+1),
        searchStr: (i+1)
    }));

    let kuralNums = [...new Array(1330)].map((_, i) => ({
        id: i+133, // 133 - 1463
        name: 'Kural ' + (i+1),
        searchStr: (i+1)
    }));

    const searchItems = chapterlNums.concat(kuralNums);

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item) => {
        // the item selected
        if (item && item.id >= 0 && item.id < 133) {
            // Go to chapter
            navigate("/ch/" + (item.id+1))
        } else if (item && item.id >= 133 && item.id < 1463) {
            // Go to Kurals
            navigate("/" + (item.id-132))
        } else {
            console.log(item)
        }
    }

    const handleOnFocus = () => {
        // console.log('Focused')
    }

    const formatResult = (item) => {
        return (
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span> 
        )
    }

    return (
        <div className="m-0 p-1" style={{ width: 250 }}>
            <ReactSearchAutocomplete
                items={searchItems}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                styling={{ height: "35px" }}
                placeholder='Kural or chapter number'
                fuseOptions={{keys: ["searchStr", "name"]}}
                resultStringKeyName="name"
            />
        </div>
    )
}