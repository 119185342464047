import { Link } from 'react-router-dom';
import TranslationPanel from './TranslationPanel';
import OtherPoetsExpPanel from './OtherPoetsExpPanel';
/*
 * Returns accordion header for each item.
 */
function AccordionHeader(props) {
    const item = props.item;

    let btnClass = "accordion-button ";
    btnClass += (item.is % 10 === 1) ? " " : "collapsed ";

    return (
        <h2 className="accordion-header" id={"heading" + item.is}>
            <button className={btnClass} type="button"
                data-bs-toggle="collapse" data-bs-target={"#Collapse" + item.id}
                aria-expanded={item.id % 10 === 1 ? "true" : "false"}
                aria-controls={"Collapse" + item.id}>
                <b>{item.id} - {item.l1}</b>
            </button>
        </h2>
    );
}

/*
 * Returns accordion body for each item.
 */
function AccordionBody(props) {
    const item = props.item;
    return (
        <div className="row">
            <div className="col-12">
              <Link className="nav-link" to={"/" + item.id}>
                <p>{item.l1}<br />
                {item.l2}</p>
              </Link>
            </div>
            <div className="col-12">
                <label className='mx-0 p-0 btn-sm'><p>{item.ex}</p></label>
            </div>
            
            <div className="col-12">
                <OtherPoetsExpPanel item={item} />
            </div>
            <div className="col-12">
                <TranslationPanel item={item} open={false}/>
            </div>
        </div>
    );
}

/*
 * Generates an accordian component.
 */
export default function Accordion(props) {

    function genCard(item, idx) {
        let accBodyClass = "accordion-collapse collapse  ";
        accBodyClass += (item.id % 10 === 1) ? "show" : "";

        return (
            <div key={item.id} className="accordion-item shadow mb-2">
                <AccordionHeader item={item} />

                <div id={"Collapse" + item.id} className={accBodyClass}
                    aria-labelledby="headingOne" data-bs-parent="#AccordionId">
                    <div className="accordion-body p-2">
                        <AccordionBody item={item} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="AccordionId" className="accordion accordion-flush mt-3">
            {props.items.map(genCard)}
        </div>
    );
}