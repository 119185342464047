import { useState } from 'react'
import { Collapse } from 'react-collapse'

/*
 * Returns the kural's class info panel.
 */
export default function KuralClassInfo(props) {
    const kural = props.item
    const [isOpened, setIsOpened] = useState(true);

    return (
        <div key={kural.id}>
            <label className='mx-0 p-0 btn-sm '
                onClick={() => { setIsOpened(!isOpened) }}>
                Class Info {isOpened ? "-" : "+"}
            </label>
            <Collapse isOpened={isOpened}>
                <div className='row m-0 p-0'>
                  <p>
                    <span>எண்:     {kural.id}<br/></span>
                    <span>பால்:     {kural.paul} ({kural.paultl})<br/></span>
                    <span>அதிகாரம்: {kural.adi} ({kural.aditl})<br/></span>
                    <span>இயல்:    {kural.iyal} ({kural.iyaltl})<br/></span>
                  </p>
                </div>
            </Collapse>
        </div>
    )
}