import { useState } from 'react'
import { Collapse } from 'react-collapse'

/*
 * Returns the kural's translation panel.
 */
export default function TranslationPanel(props) {
    const item = props.item
    const [isOpened, setIsOpened] = useState(props.open);

    return (
        <div key={item.id} className='mx-0 p-0'>
            <label className='mx-0 p-0 btn-sm '
                onClick={() => { setIsOpened(!isOpened) }}>
                Translation {isOpened ? "-" : "+"}
            </label>
            <Collapse isOpened={isOpened}>
                <div className='row m-0 p-0'>
                    <p>
                      <label className='mx-0 p-0 btn-sm'>
                        {item.tl1}<br/>
                        {item.tl2}
                      </label>
                    </p>
                    <p>
                      <label className='mx-0 p-0 btn-sm'>
                        {item.tl}
                      </label>
                    </p>
                </div>
            </Collapse>
        </div>
    )
}