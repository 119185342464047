import { HelmetProvider, Helmet } from "react-helmet-async";
import PicCard from "../comp/PicCard";

export default function About() {
  return (
    <div className="m-2">
      <HelmetProvider>
        <Helmet>
          <title>Learn Thirukkural</title>
          <meta name="description" content="Thirukkural Thirukural Thiruvalluvar Ramesh Pavadai" />
        </Helmet>
      </HelmetProvider>
      <div className="card flex-row flex-wrap border-0 m-0 p-0">
        <PicCard />
      </div>
      <div className="card-body ">
        {/* <div className="row">
          <Link className="nav-link" to="/privacy">Privacy Policy</Link>
        </div>
        <div className="row">
          <Link className="nav-link" to="/tos">Terms of Service</Link>
        </div> */}

        <div className="row p-1">
          <span> Many many thanks to &nbsp;
            <a href="https://en.wikipedia.org/wiki/Thiruvalluvar"><b>Deivappulavar Thiruvalluvar</b></a>.
          </span>
        </div>
        <div className="row p-1">
          <span> Additional Credits: <br/></span>
          <span className="mx-2"> Ramesh Pavadai <br/></span>
          <span className="mx-2"> Reshmi Ramesh <br/></span>
          <span className="mx-2"> Dr. R. Kumudhavalli (Audio Recordings)<br/></span>
          <span className="mx-2"> Dr. R. Manikandan (Audio Recordings)<br/></span>
        </div>
        <div className="row p-1">
          <span> Please provide your feedback to <a href="mailto:rameshpa@gmail.com">rameshpa@gmail.com.</a><br /></span>
        </div>
      </div>
    </div>
  );
}