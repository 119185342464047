import { useState, useEffect } from 'react';
import TranslationPanel from '../comp/TranslationPanel';
import OtherPoetsExpPanel from '../comp/OtherPoetsExpPanel';
import KuralClassInfo from '../comp/KuralClassInfo';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DatePicker from 'react-date-picker';
import PicCard from '../comp/PicCard';
/*
 * Renders the kural's page.
 */
export default function KuralDay(props) {
    const [kural, setKural] = useState({});
    const [title, setTitle] = useState('');
    const [chapter, setChapter] = useState(1);
    const [chapterLink, setChapterLink] = useState('');
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        async function fetchKural(date) {
            console.log('date is '+date);
            console.log(date.toISOString());
            const day = date.toISOString().split('T')[0].replaceAll('-','');
            const response = await fetch(
                `/.netlify/functions/kurals?${new URLSearchParams({
                    day: day
                })}`
            );

            const resJson = await response.json();
            if (!response.ok) {
                console.log(response.reason);
                return;
            }
            console.log("Response from lambda...");
            console.log(resJson);
            setKural(resJson.kurals[0]);
            const newTitle = 'Thirukkural of the day - ' + date.toISOString().split('T')[0];
            setTitle(newTitle)
            const ch = Math.ceil(resJson.kurals[0].id / 10)
            setChapter(ch);
            setChapterLink('/ch/' + ch);
        }
        fetchKural(date);
    }, [date]);

    return (
        <div className="m-2">
            <HelmetProvider>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            </HelmetProvider>
            {/* <div className="card-header border-0 m-0 p-0 m-0 p-0 col-md-6 col-lg-6 col-xl-5 col-xxl-4" style={{ height: 200 }}>
                <img className="w-100 h-100" src="/Thiru.png" alt="" />
            </div> */}
            <PicCard />
            <div >
                <br/>
                <b>Thirukkural of the day</b>:
                <DatePicker 
                    onChange={setDate}
                    value={date}
                    minDate={new Date(2022, 2, 1)}
                    maxDate={new Date()}
                    required={true}
                    clearIcon={null}
                />
            </div>
            <div className='mx-0'>
                <nav aria-label="breadcrumb" className='mx-0' style={{ bsBreadcrumbDivider: '>'}}>
                    <ol className="breadcrumb px-0">
                        <li className="breadcrumb-item px-0 m-0 btn-sm"><a href="/#">Home </a></li>
                        <li className="breadcrumb-item btn-sm">
                            <a href={chapterLink}>Chapter {chapter} </a>
                        </li>
                        <li className="breadcrumb-item p-0 bt-sm active" aria-current="page">
                        <label className='px-0 btn-sm'>Kural {kural.id}</label> </li>
                    </ol>
                </nav>
            </div>
            <div className="col-12">
                <h3>
                    {kural.l1}<br />
                    {kural.l2}
                </h3>
            </div>
            <div className="col-12">
                <label className='mx-0 p-0 btn-sm'><p>{kural.ex}</p></label>
            </div>
            <div className="col-12">
                <KuralClassInfo item={kural} />
            </div>
            <div className="col-12">
                <OtherPoetsExpPanel item={kural} mk={true} />
            </div>
            <div className="col-12">
                <TranslationPanel item={kural} open={true} />
            </div>
        </div>
    );
}