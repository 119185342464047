import { Link } from "react-router-dom";

export default function Footer(props) {
    return (
      <div className="bg-dark text-white">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link text-white" to="/#">Thirukkural</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white" to="/ch/1">Chapters</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white" to="/about">About</Link>
          </li>
        </ul>
      </div>
    );
}