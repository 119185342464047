import { Helmet, HelmetProvider } from 'react-helmet-async';
import IntroCard from '../comp/IntroCard';
import PalsCard from '../comp/PalsCard';
import PicCard from '../comp/PicCard';

export default function Home(props) {
    return (
        <div className="m-2">
            <HelmetProvider>
                <Helmet>
                    <title>Learn Thirukkural</title>
                </Helmet>
            </HelmetProvider>
            <div className="card flex-row flex-wrap border-0 m-0 p-0">
                {/* <div className="card-header border-0 m-0 p-0 col-md-6 col-lg-6 col-xl-5 col-xxl-4" style={{ height: 250 }}>
                    <img className="w-100 h-100 img-fluid" src="/Thiru.png" alt="" />
                </div> */}
                <PicCard />
                <div className="card-body m-0 p-0">
                    <h5 className="card-title">
                        Introduction - திருவள்ளுவரின் திருக்குறள்
                    </h5>
                    <IntroCard />
                    <PalsCard />
                </div>
            </div>
        </div>
    );
}